//=================================== CHANGE ENVIRONMENT ==================================
    const option = 'development'
//=========================================================================================

function env(env) {
    switch (env) {
        case 'local':
            return ['http://172.16.1.6:4200/rules/api',env,'grey darken-1','http://des-reglas.mitur.gob.do/']
        case 'server-lovera':
            return ['http://159.223.159.20/node/api-rules/rules/api',env,'secondary-color','http://des-reglas.mitur.gob.do/']
        case 'development':
            return ['https://dev.apireglas.servicios.mitur.gob.do/rules/api',env, 'orange darken-2','https://rules.servicios.mitur.gob.do/']
        case 'production':
            return ['https://dev.apireglas.servicios.mitur.gob.do/rules/api',env, 'orange darken-2','https://rules.servicios.mitur.gob.do/']
        default:
            return ''
    }
}
try{
    module.exports ={
        api:    env(option)[0],
        env:    env(option)[1],
        color:  env(option)[2],
        qrApi: env(option)[3],
        SECRET_TOKEN: 'palolo'
    }
}catch(e){
}
